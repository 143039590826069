import { Box, Spinner, Text } from "@chakra-ui/react";

const LoadingInterstitial = ({ text }: { text?: string }) => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(255, 255, 255, 0.8)"
      zIndex="1000"
    >
      {text && (
        <Text mb={4} fontSize="xl">
          {text}
        </Text>
      )}
      <Spinner size="xl" />
    </Box>
  );
};

export default LoadingInterstitial;
