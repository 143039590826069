import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Box,
  Textarea,
  useToast,
} from "@chakra-ui/react";

const BuildTrigger = () => {
  const [repositoryFullName, setRepositoryFullName] = useState("");
  const [ref, setRef] = useState("");
  const [packageInstallCommand, setPackageInstallCommand] = useState("");
  const [buildDir, setBuildDir] = useState("");
  const [buildCommand, setBuildCommand] = useState("");
  const [outputDir, setOutputDir] = useState("");
  const [environmentVariables, setEnvironmentVariables] = useState("");
  const toast = useToast();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const payload = {
      REPOSITORY: repositoryFullName,
      REF: ref,
      PACKAGE_INSTALL_COMMAND: packageInstallCommand,
      BUILD_DIR: buildDir,
      BUILD_COMMAND: buildCommand,
      OUTPUT_DIR: outputDir,
      ENVIRONMENT_VARIABLES: environmentVariables,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/trigger-build`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        },
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      toast({
        title: "Build Triggered Successfully",
        description: `Details: ${JSON.stringify(data)}`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error Triggering Build",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel htmlFor="repositoryFullName">
              Repository Full Name
            </FormLabel>
            <Input
              id="repositoryFullName"
              value={repositoryFullName}
              onChange={(e) => setRepositoryFullName(e.target.value)}
              placeholder="username/repository"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="ref">Branch Reference</FormLabel>
            <Input
              id="ref"
              value={ref}
              onChange={(e) => setRef(e.target.value)}
              placeholder="Default: main"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="packageInstallCommand">
              Package Install Command
            </FormLabel>
            <Input
              id="packageInstallCommand"
              value={packageInstallCommand}
              onChange={(e) => setPackageInstallCommand(e.target.value)}
              placeholder="npm install or custom command"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="buildDir">Build Directory</FormLabel>
            <Input
              id="buildDir"
              value={buildDir}
              onChange={(e) => setBuildDir(e.target.value)}
              placeholder="Directory to run build commands"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="buildCommand">Build Command</FormLabel>
            <Input
              id="buildCommand"
              value={buildCommand}
              onChange={(e) => setBuildCommand(e.target.value)}
              placeholder="npm run build or custom command"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="outputDir">Output Directory</FormLabel>
            <Input
              id="outputDir"
              value={outputDir}
              onChange={(e) => setOutputDir(e.target.value)}
              placeholder="Directory for build outputs"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="environmentVariables">
              Environment Variables (Base64 Encoded)
            </FormLabel>
            <Textarea
              id="environmentVariables"
              value={environmentVariables}
              onChange={(e) => setEnvironmentVariables(e.target.value)}
              placeholder="Base64 encoded variables"
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" size="lg">
            Trigger Build
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default BuildTrigger;
