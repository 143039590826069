import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
} from "@chakra-ui/react";

interface ProjectFormProps {
  onSubmit: (formData: any) => void;
  projectUrlEnabled?: boolean;
  buildEnabled?: boolean;
  additionalInput?: React.ReactNode;
  isEditing?: boolean;
  initialValues?: ProjectObject;
}

const initialState = {
  name: "",
  email: "",
  buildCommands: {
    buildCommand: "",
    buildDir: "",
    environmentVariables: "",
    outputDir: "",
    packageInstallCommand: "",
  },
  hostedProjectUrl: "",
  projectType: "",
  projectInfo: {
    url: "",
    repositoryId: "",
    repositoryFullName: "",
    filePath: "",
  },
};

export const ProjectForm = ({
  onSubmit,
  projectUrlEnabled,
  buildEnabled,
  additionalInput,
  isEditing = false,
  initialValues,
}: ProjectFormProps) => {
  const [projectData, setProjectData] = useState({
    ...initialState,
    ...initialValues,
    buildCommands: {
      ...initialState.buildCommands,
      ...initialValues?.buildCommands,
    },
    projectInfo: {
      ...initialState.projectInfo,
      ...initialValues?.projectInfo,
    },
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    if (projectData.buildCommands && name in projectData.buildCommands) {
      setProjectData((prev) => ({
        ...prev,
        buildCommands: { ...prev.buildCommands, [name]: value },
      }));
    } else if (projectData.projectInfo && name in projectData.projectInfo) {
      setProjectData((prev) => ({
        ...prev,
        projectInfo: { ...prev.projectInfo, [name]: value },
      }));
    } else {
      setProjectData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(projectData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4} align="center" m={5}>
        <FormControl isRequired>
          <FormLabel>Project Name</FormLabel>
          <Input
            name="name"
            value={projectData.name}
            onChange={handleInputChange}
            placeholder="Enter project name"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Contact Email</FormLabel>
          <Input
            name="email"
            type="email"
            value={projectData.email}
            onChange={handleInputChange}
            placeholder="Enter contact email"
          />
        </FormControl>
        {buildEnabled && (
          <>
            <FormControl isRequired>
              <FormLabel>Package Install Command</FormLabel>
              <Input
                name="packageInstallCommand"
                value={projectData.buildCommands.packageInstallCommand}
                onChange={handleInputChange}
                placeholder="e.g., npm install or yarn install"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Build Command</FormLabel>
              <Input
                name="buildCommand"
                value={projectData.buildCommands.buildCommand}
                onChange={handleInputChange}
                placeholder="e.g., npm run build"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Build Directory</FormLabel>
              <Input
                name="buildDir"
                value={projectData.buildCommands.buildDir}
                onChange={handleInputChange}
                placeholder="Directory to run build commands (e.g., src/)"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Static Assets Output Directory</FormLabel>
              <Input
                name="outputDir"
                value={projectData.buildCommands.outputDir}
                onChange={handleInputChange}
                placeholder="e.g., build/output"
              />
            </FormControl>
          </>
        )}

        {projectUrlEnabled && (
          <FormControl isRequired>
            <FormLabel>Project Url</FormLabel>
            <Input
              name="url"
              value={projectData.projectInfo.url}
              onChange={handleInputChange}
              placeholder="Enter project URL"
            />
          </FormControl>
        )}
        <FormControl>
          <FormLabel>Environment Variables</FormLabel>
          <Textarea
            name="environmentVariables"
            value={projectData.buildCommands.environmentVariables}
            onChange={handleInputChange}
            placeholder="Enter environment variables"
            rows={4}
          />
        </FormControl>
        {additionalInput}
        <Button type="submit" colorScheme="teal">
          {isEditing ? "Update Project" : "Create Project"}
        </Button>
      </VStack>
    </form>
  );
};
