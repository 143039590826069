import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useToast } from "@chakra-ui/react";
import { ProjectForm } from "./components/ProjectForm";
import { createProject } from "./api";
import LoadingInterstitial from "./components/LoadingInterstitial";

const AddProjectByUrl = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [projectCreating, setProjectCreating] = useState(false);

  const validateUrl = (inputUrl: string) => {
    const regex =
      /^https:\/\/github\.com\/[a-zA-Z0-9_-]+\/[a-zA-Z0-9_-]+(\/|\.git)?$/;
    return regex.test(inputUrl);
  };

  const handleSubmit = async (formData: any) => {
    const { name, email, buildCommands, projectInfo } = formData;
    if (!name || !email || !buildCommands || !projectInfo) {
      toast({
        title: "Missing information",
        description: "Please fill all required fields before submitting.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!validateUrl(projectInfo.url)) {
      toast({
        title: "Invalid GitHub URL",
        description: "Please provide a valid GitHub URL.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setProjectCreating(true);
      const response = await createProject({
        name: name,
        email: email,
        buildCommands: buildCommands,
        projectType: "url",
        projectInfo: {
          url: projectInfo.url,
          domain: "github",
        },
      });

      setProjectCreating(false);

      if (!response.ok) {
        throw new Error("Failed to create project.");
      }

      toast({
        title: "Project Created",
        description: "Project successfully created.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/projects");
    } catch (error) {
      setProjectCreating(false);
      const message =
        error instanceof Error ? error.message : "Failed to create project";

      toast({
        title: "Failed to create project",
        description: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <ProjectForm buildEnabled projectUrlEnabled onSubmit={handleSubmit} />
      {projectCreating && (
        <LoadingInterstitial text="Creating Project on chain. This can take a minute or two" />
      )}
    </Box>
  );
};

export default AddProjectByUrl;
