import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast, Box } from "@chakra-ui/react";
import { ProjectForm } from "./components/ProjectForm";
import { createProject } from "./api";
import LoadingInterstitial from "./components/LoadingInterstitial";

const ManualProjectUpload = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [projectCreating, setProjectCreating] = useState(false);

  const handleSubmit = async (formData: ProjectObject) => {
    const { name, email, buildCommands } = formData;
    const environmentVariables = buildCommands.environmentVariables;
    if (!name || !email) {
      toast({
        title: "Missing information",
        description: "Please fill all required fields before submitting.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setProjectCreating(true);
      const response = await createProject({
        name: name,
        email: email,
        projectType: "upload",
        buildCommands: {
          environmentVariables: environmentVariables,
        },
      });

      setProjectCreating(false);

      if (!response.ok) {
        throw new Error("Failed to create project.");
      }

      const data = await response.json();
      toast({
        title: "Project Created",
        description: "Project successfully created. You can now upload files.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate(`/projects/${data.id}/deploy`); // TODO: This doesn't exist
    } catch (error) {
      setProjectCreating(false);
      const message =
        error instanceof Error ? error.message : "Failed to create project";

      toast({
        title: "Failed to create project",
        description: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <ProjectForm onSubmit={handleSubmit} />
      {projectCreating && (
        <LoadingInterstitial text="Creating Project on chain. This can take a minute or two" />
      )}
    </Box>
  );
};

export default ManualProjectUpload;
