import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Callback from "./Callback";
import { Flex, ChakraProvider, extendTheme } from "@chakra-ui/react";

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Login from "./Login";
import Signup from "./Signup";
import Navbar from "./Navbar";
import Projects from "./Projects";
import ProjectEdit from "./ProjectEdit";
import ViewSite from "./ViewSite";
import GitHubProjects from "./GithubProjects";
import CreateProject from "./CreateProject";
import BuildTrigger from "./components/BuildTrigger";
import AddProjectByUrl from "./AddProjectByUrl";
import ManualProjectUpload from "./ManualProjectUpload";
import FileDeployment from "./FileDeployment";
import LogPage from "./LogPage";
import ProjectUpdatesList from "./components/ProjectUpdatesList";
import { UserProvider, useUser } from "./UserContext";

const serverUrl = process.env.REACT_APP_SERVER_URL;

function UserStateManager() {
  const { setUser } = useUser();

  const logoutFn = () => {
    //make a post request to server
    fetch(`${serverUrl}/logout`, { method: "POST", credentials: "include" })
      .then(() => {
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
    setUser(null);
  };

  React.useEffect(() => {
    fetch(`${serverUrl}/user`, { credentials: "include" }).then((response) => {
      response
        .json()
        .then((data) => {
          setUser(data);
        })
        .catch((error) => {
          console.error("Error:", error);
          setUser(null);
        });
    });
  }, [setUser]);

  const router = createBrowserRouter([
    {
      path: "/",
      // redirect to projects page by default
      element: <Navigate to="/projects" replace />,
    },
    {
      path: "/projects",
      element: <Projects />,
    },
    // {
    //   path: "/explorer",
    //   element: <Home />,
    // },
    {
      path: "/project_updates/:projectId",
      element: <ProjectUpdatesList />,
      loader: async ({ params }) => {
        return { projectId: params.projectId };
      },
    },
    {
      path: "/logs/:pipelineId",
      element: <LogPage />,
    },
    {
      path: "/trigger-build",
      element: <BuildTrigger />,
    },
    {
      path: "/add-github-repo",
      element: <GitHubProjects />,
    },
    {
      path: "/add-project-url",
      element: <AddProjectByUrl />,
    },
    {
      path: "/add-project-manual",
      element: <ManualProjectUpload />,
    },
    {
      path: "/projects/create",
      element: <CreateProject />,
    },
    {
      path: "/projects/:projectId/deploy",
      element: <FileDeployment />,
    },
    {
      path: "/projects/:projectId/edit",
      element: <ProjectEdit />,
    },
    {
      path: "/github/callback",
      element: <Callback />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/view-site/:url",
      element: <ViewSite />,
      loader: async ({ params }) => {
        return { url: params.url };
      },
    },
    {
      path: "/:catchAll",
      element: <div>Not found</div>,
    },
  ]);

  return (
    <Flex direction="column" h="100vh">
      <Navbar logoutFn={logoutFn}></Navbar>
      <RouterProvider router={router}></RouterProvider>
    </Flex>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const theme = extendTheme({
  config: {
    initialColorMode: "light",
  },
});

root.render(
  <ChakraProvider
    theme={theme}
    toastOptions={{ defaultOptions: { position: "top" } }}
  >
    <UserProvider>
      <UserStateManager />
    </UserProvider>
  </ChakraProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
