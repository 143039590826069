import React, { createContext, useState, useContext, ReactNode } from "react";

const serverUrl = process.env.REACT_APP_SERVER_URL;

// Define the context value shape
interface UserContextType {
  user: UserObject | null;
  setUser: React.Dispatch<React.SetStateAction<UserObject | null>>;
}

// Create a context with default value
const UserContext = createContext<UserContextType | undefined>(undefined);

// Create a provider component
interface UserProviderProps {
  children: ReactNode;
}

export async function fetchUser(): Promise<UserObject | null> {
  try {
    const response = await fetch(`${serverUrl}/user`, {
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data: UserObject = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<UserObject | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Create a custom hook to use the UserContext
export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
