import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, useToast, Center } from "@chakra-ui/react";
import { ProjectForm } from "./components/ProjectForm";
import { getProject, updateProject } from "./api";

export default function ProjectEdit() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [projectData, setProjectData] = useState<ProjectObject | null>(null);

  useEffect(() => {
    if (projectId) {
      getProject(projectId)
        .then((data) => {
          setProjectData(data);
        })
        .catch(() => {
          toast({
            title: "Error",
            description: "Failed to fetch project data.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  }, [projectId, toast]);

  const handleSubmit = async (formData: any) => {
    if (!projectId) {
      toast({
        title: "Error",
        description: "Project ID is missing.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await updateProject(projectId, formData);
      if (!response.ok) {
        throw new Error("Failed to update project.");
      }

      toast({
        title: "Project Updated",
        description: "Project successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/projects");
    } catch (error) {
      toast({
        title: "Update Failed",
        description: "Failed to update the project.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={5} borderWidth="0px" borderRadius="lg">
      <Center>
        <Box width={{ base: "90%", md: "80%" }} minWidth="300px">
          {projectData && (
            <ProjectForm
              onSubmit={handleSubmit}
              projectUrlEnabled={projectData.projectType === "url"}
              buildEnabled={projectData.projectType !== "upload"}
              isEditing={true}
              initialValues={projectData}
            />
          )}
        </Box>
      </Center>
    </Box>
  );
}
