import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  VStack,
  Box,
  Text,
  Spinner,
  useToast,
  Button,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";

interface Log {
  job: {
    name: string;
    status: string;
    started_at: string;
    stopped_at: string;
  };
  steps: Step[];
}

interface Step {
  name: string;
  actions: Action[];
}

interface Action {
  name: string;
  status: string;
  start_time: string;
  end_time: string;
  output: string;
}

const LogPage = () => {
  const { pipelineId } = useParams<{ pipelineId: string }>();
  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/circleci/logs/${pipelineId}`,
        {
          credentials: "include",
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch logs");
      }

      const data = await response.json();
      setLogs(data);
    } catch (error) {
      console.error("Error fetching logs:", error);
      toast({
        title: "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [pipelineId]);

  const parseOutput = (output: string) => {
    try {
      const parsedOutput = JSON.parse(output);
      return parsedOutput.map((entry: any) => entry.message).join("\n");
    } catch (error) {
      console.error("Error parsing output:", error);
      return "Error parsing output";
    }
  };

  return (
    <VStack spacing={4} mt={4}>
      {loading ? (
        <Spinner />
      ) : (
        logs.map((log, index) => (
          <Box key={index} w="80%" p={4} borderRadius="md" shadow="base">
            <Text fontWeight="bold">Job: {log.job.name}</Text>
            <Text>Status: {log.job.status}</Text>
            <Text>Started at: {log.job.started_at}</Text>
            <Text>Stopped at: {log.job.stopped_at}</Text>
            {log.steps.map((step, stepIndex) => (
              <Box key={stepIndex} mt={4}>
                <Text fontWeight="bold">Step: {step.name}</Text>
                {step.actions.map((action, actionIndex) => (
                  <Box key={actionIndex} ml={4} mt={2}>
                    <Text>Action: {action.name}</Text>
                    <Text>Status: {action.status}</Text>
                    <Text>Start time: {action.start_time}</Text>
                    <Text>End time: {action.end_time}</Text>
                    <CollapsibleOutput output={parseOutput(action.output)} />
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        ))
      )}
      <Button onClick={() => navigate(-1)} colorScheme="blue">
        Back
      </Button>
    </VStack>
  );
};

const CollapsibleOutput = ({ output }: { output: string }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box mt={2}>
      <Button size="sm" onClick={onToggle}>
        {isOpen ? "Hide Output" : "View Output"}
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Box
          p={4}
          mt={2}
          // bg="gray.50"
          borderRadius="md"
          shadow="sm"
          maxH="300px"
          overflowY="auto"
        >
          <pre>{output}</pre>
        </Box>
      </Collapse>
    </Box>
  );
};

export default LogPage;
