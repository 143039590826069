import React, { useState } from "react";
import {
  Box,
  VStack,
  Input,
  Button,
  FormControl,
  FormLabel,
  useToast,
  Flex,
  Center,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import FolderUpload from "./components/FolderUpload";

const FileDeployment = () => {
  const [folderFiles, setFolderFiles] = useState<any[]>([]);
  const [bundleUrl, setBundleUrl] = useState("");
  const [checksum, setChecksum] = useState("");
  const [uploadMethod, setUploadMethod] = useState("file");
  const toast = useToast();
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();

  const handleFileSubmission = async () => {
    if (uploadMethod === "file" && folderFiles.length === 0) {
      toast({
        title: "No folder provided",
        description:
          "Please upload a folder containing index.html before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (uploadMethod === "url" && (!bundleUrl || !checksum)) {
      toast({
        title: "Incomplete input",
        description:
          "Please provide both a bundle URL and checksum before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      if (uploadMethod === "file") {
        await uploadFolder(folderFiles);
      } else if (uploadMethod === "url") {
        await createProjectUpdate(bundleUrl, checksum);
      }
    } catch (error) {
      toast({
        title: "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const uploadFolder = async (files: any[]) => {
    const formData = new FormData();
    files.forEach(({ file, path }) => formData.append("files", file, path));

    const uploadResponse = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/upload-folder`,
      {
        method: "POST",
        body: formData,
        credentials: "include",
      },
    );

    if (!uploadResponse.ok) throw new Error("Failed to upload folder.");
    const uploadData = await uploadResponse.json();
    await triggerBuildWithBundle(uploadData.folderUrl, "");
  };

  const triggerBuildWithBundle = async (url: string, checksum: string) => {
    const buildPayload = {
      projectId: projectId,
      projectInfo: {
        url: url,
        checksum: checksum,
      },
    };
    const buildResponse = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/trigger-build`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(buildPayload),
        credentials: "include",
      },
    );

    if (!buildResponse.ok) throw new Error("Failed to trigger build.");
    toast({
      title: "Build Triggered Successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate(`/project_updates/${projectId}`);
  };

  const createProjectUpdate = async (bundleUrl: string, checksum: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/project_updates/${projectId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ bundleUrl, checksum }),
        credentials: "include",
      },
    );

    if (!response.ok) throw new Error("Failed to create project update.");
    toast({
      title: "Project Update Created Successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate(`/project_updates/${projectId}`);
  };

  const renderUploadComponent = () => {
    if (uploadMethod === "file") {
      return (
        <Box>
          <FolderUpload onFilesSelected={setFolderFiles} />
        </Box>
      );
    } else if (uploadMethod === "url") {
      return (
        <>
          <FormControl>
            <FormLabel>Bundle URL</FormLabel>
            <Input
              value={bundleUrl}
              onChange={(e) => setBundleUrl(e.target.value)}
              placeholder="Enter bundle URL"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Checksum</FormLabel>
            <Input
              value={checksum}
              onChange={(e) => setChecksum(e.target.value)}
              placeholder="Enter checksum"
            />
          </FormControl>
        </>
      );
    }
  };

  return (
    <Box width="full" maxWidth="70%" margin="0 auto" padding={5}>
      <Flex justify="space-around" mb={4}>
        <Center>
          <Button
            colorScheme="teal"
            borderRadius={0}
            variant={uploadMethod === "file" ? "solid" : "outline"}
            onClick={() => setUploadMethod("file")}
          >
            Upload
          </Button>
          <Button
            colorScheme="teal"
            borderRadius={0}
            variant={uploadMethod === "url" ? "solid" : "outline"}
            onClick={() => setUploadMethod("url")}
          >
            URL
          </Button>
        </Center>
      </Flex>
      <VStack spacing={4} align="center">
        {renderUploadComponent()}
        <Button colorScheme="blue" onClick={handleFileSubmission}>
          Create Build
        </Button>
      </VStack>
    </Box>
  );
};

export default FileDeployment;
