import React from "react";
import {
  Center,
  Divider,
  Flex,
  Image,
  Link,
  Spacer,
  Switch,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import GithubAuthButton from "./components/GithubAuthButton";

import { useUser } from "./UserContext";

export default function Navbar({ logoutFn }: { logoutFn: () => void }) {
  const { user } = useUser();
  const { colorMode, toggleColorMode } = useColorMode();
  const loggedIn = user && user.userId;

  return (
    <Flex
      backgroundColor="rgb(0,1,26);"
      height={50}
      minHeight={50}
      minWidth="max-content"
      alignItems="center"
      gap="2"
      paddingX="20px"
      marginBottom="20px"
    >
      <Image
        src="/earthfast.png"
        height="35px"
        verticalAlign={"center"}
      ></Image>
      <Text
        verticalAlign={"center"}
        textDecor="none"
        fontSize="lg"
        fontWeight="600"
        color="white"
      >
        EarthFast Dashboard
      </Text>
      <Divider
        backgroundColor={"white"}
        orientation="vertical"
        height="25px"
        marginX="20px"
      />
      <Link
        textDecor="none"
        fontSize="md"
        fontWeight="500"
        color="white"
        href="/projects"
      >
        Projects
      </Link>
      {/* Comment out Explorer for now */}
      {/* <Divider backgroundColor={"white"} orientation="vertical" height="25px" marginX="20px" />
      <Link
        textDecor="none"
        fontSize="md"
        fontWeight="500"
        color="white"
        href="/explorer"
      >
        Explorer
      </Link> */}
      <Spacer />
      {/* to set this manually set `chakra-ui-color-mode` to `light` or `dark` in localstorage */}
      {/* <VStack>
        <Text fontSize="xs" color="white">
          Dark Mode?
        </Text>
        <Switch
          onChange={() => {
            console.log("toggle color mode", colorMode);
            toggleColorMode();
          }}
        ></Switch>
      </VStack> */}
      <Divider
        backgroundColor={"white"}
        orientation="vertical"
        height="20px"
        marginX="20px"
      />
      {loggedIn ? (
        <>
          {user.github?.user?.avatar_url ? (
            <Image
              src={user.github?.user?.avatar_url}
              borderRadius="50%"
              height="30px"
            />
          ) : (
            <> </>
          )}
          <Center>
            {!user?.github?.installationId && (
              <>
                <GithubAuthButton
                  Component={Link}
                  props={{
                    textDecor: "none",
                    fontSize: "md",
                    fontWeight: "500",
                    color: "white",
                  }}
                  displayText="Authorize GitHub"
                />
                <Divider
                  backgroundColor={"white"}
                  orientation="vertical"
                  height="20px"
                  marginX="20px"
                />
              </>
            )}
            <Text fontSize="sm" color="white">
              {user.github?.user?.login
                ? `@${user.github?.user?.login}`
                : user.email}
            </Text>
            <Divider
              backgroundColor={"white"}
              orientation="vertical"
              height="20px"
              marginX="20px"
            />
            <Link color="white" onClick={logoutFn}>
              Logout
            </Link>
          </Center>
        </>
      ) : (
        <>
          <Link
            textDecor="none"
            fontSize="md"
            fontWeight="500"
            color="white"
            href="/login"
          >
            Login
          </Link>
          <Divider
            backgroundColor={"white"}
            orientation="vertical"
            height="20px"
            marginX="20px"
          />
          <Link
            textDecor="none"
            fontSize="md"
            fontWeight="500"
            color="white"
            href="/signup"
          >
            Sign Up
          </Link>
        </>
      )}
    </Flex>
  );
}
