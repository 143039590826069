import {
  Text,
  Flex,
  ButtonGroup,
  Button,
  VStack,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { IoLogoGithub } from "react-icons/io";
import { FaRegListAlt, FaFileUpload, FaRegTrashAlt } from "react-icons/fa";
import { GoLinkExternal } from "react-icons/go";

interface Props {
  project: ProjectObject;
  onDelete: (id: number) => Promise<void>;
}

const ProjectItem = ({ project, onDelete }: Props) => {
  const handleDelete = () => {
    onDelete(project.id).catch((error) => {
      alert(`Failed to delete project: ${error.message || "Server error"}`);
    });
  };

  const ProjectInfo = (project: ProjectObject) => {
    if (project.projectType === "github") {
      const projectInfo = project.projectInfo as ProjectInfoGithub;
      return (
        <ChakraLink
          href={`https://github.com/${projectInfo?.repositoryFullName}`}
          fontSize="sm"
          display="inline"
          marginLeft={1}
          target="_blank"
        >
          <IoLogoGithub style={{ display: "inline" }} />
          <Text display={"inline"} marginLeft={1}>
            {projectInfo?.repositoryFullName}
          </Text>
        </ChakraLink>
      );
    } else if (project.projectType === "url") {
      return (
        <ChakraLink
          href={(project.projectInfo as ProjectInfoUrl)?.url}
          display="inline"
          marginLeft={1}
          fontSize="sm"
          color="gray.600"
          // _hover={{ bg: "teal.50" }}
          target="_blank"
        >
          Github URL: {(project.projectInfo as ProjectInfoUrl)?.url}
        </ChakraLink>
      );
    } else if (project.projectType === "upload") {
      return <Text fontSize="sm">Manual Upload</Text>;
    }
  };

  return (
    <Flex
      width="100%"
      height="auto"
      alignItems="center"
      p={4}
      justifyContent="space-between"
    >
      <VStack alignItems="flex-start" spacing={1}>
        <ChakraLink fontSize="lg" href={`/projects/${project.id}/edit`}>
          <Text fontWeight="bold" fontSize="lg">
            {project.name}
          </Text>
        </ChakraLink>
        <span>{ProjectInfo(project)}</span>
      </VStack>
      <ButtonGroup variant="outline" spacing="2">
        {project.projectType === "upload" && (
          <Button
            as={Link}
            to={`/projects/${project.id}/deploy`}
            colorScheme="green"
            rightIcon={<FaFileUpload />}
          >
            Upload
          </Button>
        )}
        <Button
          colorScheme="gray"
          as={Link}
          to={`/project_updates/${project.id}`}
          rightIcon={<FaRegListAlt />}
        >
          View Builds
        </Button>
        {project.hostedProjectUrl && (
          <Button
            as="a"
            href={`https://${project.hostedProjectUrl}`}
            colorScheme="gray"
            rightIcon={<GoLinkExternal />}
            target="_blank"
          >
            Visit Site
          </Button>
        )}
        <Button
          colorScheme="red"
          onClick={handleDelete}
          rightIcon={<FaRegTrashAlt />}
        >
          Delete
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default ProjectItem;
