export const getProject = (projectId: string) =>
  fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectId}`, {
    method: "GET",
    credentials: "include",
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Failed to fetch project");
    }
    return response.json();
  });

export const createProject = (body: any) =>
  fetch(`${process.env.REACT_APP_SERVER_URL}/project`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    credentials: "include",
  });

export const updateProject = (projectId: string, body: any) =>
  fetch(`${process.env.REACT_APP_SERVER_URL}/project/${projectId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
    credentials: "include",
  });
