import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
  Divider,
  Heading,
  ButtonGroup,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import AddProjectButtons from "./components/AddProjectButtons";
import ProjectItem from "./components/ProjectItem";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export default function Projects() {
  const [projects, setProjects] = useState<ProjectObject[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleDeleteProject = async (id: number): Promise<void> => {
    try {
      const response = await fetch(`${serverUrl}/project/${id}`, {
        method: "DELETE",
        credentials: "include",
      });

      if (response.ok) {
        setProjects((prevProjects) =>
          prevProjects.filter((proj) => proj.id !== id),
        );
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || "Server error");
      }
    } catch (error) {
      alert("Failed to delete project");
    }
  };

  useEffect(() => {
    fetch(`${serverUrl}/user`, { credentials: "include" })
      .then((response) =>
        response.ok
          ? response.json()
          : Promise.reject(new Error("Not authenticated")),
      )
      .then(() => {
        setIsAuthenticated(true);
        return fetch(`${serverUrl}/projects`, { credentials: "include" });
      })
      .then((response) => response.json())
      .then((data) => setProjects(data))
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error(
          "Authentication check or project fetch failed:",
          error.message,
        );
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, [navigate]);

  const authenticatedWithProjects = () => (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        w={{ base: "90%", md: "80%" }}
        mb={4}
      >
        <Text fontSize="3xl" fontWeight="bold">
          My Projects
        </Text>
        <AddProjectButtons />
      </Flex>
      <Box w={{ base: "90%", md: "80%" }} borderWidth="1px" borderRadius="md">
        {projects.map((proj) => (
          <>
            <ProjectItem
              key={proj.id}
              project={proj}
              onDelete={handleDeleteProject}
            />
            <Divider />
          </>
        ))}
      </Box>
    </>
  );

  const welcomeScreen = (content: JSX.Element) => (
    <VStack spacing={12} maxWidth={"60%"} marginTop={"10%"}>
      <Heading as="h1" size="2xl" textAlign="center">
        Welcome to the EarthFast Dashboard
      </Heading>
      <Text fontSize="lg" textAlign="center">
        This dashboard allows you to create and manage website projects on
        EarthFast effortlessly. There are no prerequisites to creating projects,
        no blockchain experience, no tokens necessary. Just dive in and start
        building!
      </Text>
      {content}
    </VStack>
  );

  const authenticatedNoProjects = () =>
    welcomeScreen(
      <>
        <Text fontSize="lg" textAlign="center">
          Get started by adding a new project!
        </Text>
        <AddProjectButtons buttonProps={{ size: "lg" }} />
      </>,
    );

  const unauthenticated = () =>
    welcomeScreen(
      <>
        <Text fontSize="lg" textAlign="center">
          Sign Up for a free account or Log In to get started!
        </Text>
        <ButtonGroup>
          <Button size="lg" as={Link} to="/login" colorScheme="teal">
            Log In
          </Button>
          <Button size="lg" as={Link} to="/signup" colorScheme="teal">
            Sign Up
          </Button>
        </ButtonGroup>
      </>,
    );

  function render() {
    if (isLoading) {
      return (
        <>
          {/* skeleton tiles if we need it */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            w={{ base: "90%", md: "80%" }}
            mb={4}
          >
            {/* <Text fontSize="3xl" fontWeight="bold">
              My Projects
            </Text>
            <AddProjectButtons /> */}
          </Flex>
          <Box
            w={{ base: "90%", md: "80%" }}
            borderWidth="1px"
            borderRadius="md"
          >
            <Stack>
              <Skeleton height="60px" />
              <Skeleton height="60px" />
              <Skeleton height="60px" />
            </Stack>
          </Box>
        </>
      );
    }

    if (isAuthenticated && projects.length > 0) {
      return authenticatedWithProjects();
    } else if (isAuthenticated && projects.length === 0) {
      return authenticatedNoProjects();
    } else if (!isAuthenticated) {
      return unauthenticated();
    }
  }

  return (
    <Flex direction="column" align="center" mt={10}>
      {render()}
    </Flex>
  );
}
