import {
  Text,
  Flex,
  Button,
  VStack,
  HStack,
  Collapse,
  Box,
  Spinner,
  useDisclosure,
  useToast,
  Divider,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";

interface ProjectUpdate {
  id: number;
  circleciJobId: string;
  status: string;
  bundleUrl: string;
  checksum: string;
}

interface Log {
  job: {
    name: string;
    status: string;
    started_at: string;
    stopped_at: string;
  };
  steps: Step[];
}

interface Step {
  name: string;
  actions: Action[];
}

interface Action {
  name: string;
  status: string;
  start_time: string;
  end_time: string;
  output: string;
}

export default function ProjectUpdatesList() {
  const data = useLoaderData() as { projectId: number };
  const projectId = data.projectId;
  const [projectUpdates, setProjectUpdates] = useState<ProjectUpdate[]>([]);
  const [logs, setLogs] = useState<Record<string, Log[]>>({});
  const [loadingLogs, setLoadingLogs] = useState<Record<string, boolean>>({});
  const [selectedPipelineId, setSelectedPipelineId] = useState<string | null>(
    null,
  );
  const toast = useToast();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/project_updates/${projectId}`, {
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch project updates");
        }
        return response.json();
      })
      .then((data) => {
        const sortedData = data.sort(
          (a: ProjectUpdate, b: ProjectUpdate) => b.id - a.id,
        );
        setProjectUpdates(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching project updates:", error);
      });
  }, [projectId]);

  const navigate = useNavigate();

  const fetchLogs = async (pipelineId: string) => {
    navigate(`/logs/${pipelineId}`);
  };

  const handleTriggerNewBuild = async (update: ProjectUpdate) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/project_updates/${projectId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            bundleUrl: update.bundleUrl,
            checksum: update.checksum,
          }),
          credentials: "include",
        },
      );

      if (!response.ok) {
        throw new Error("Failed to trigger new build");
      }

      const newUpdate = await response.json();
      setProjectUpdates((prevUpdates) => [newUpdate, ...prevUpdates]);

      toast({
        title: "New build triggered successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error triggering new build:", error);
      toast({
        title: "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" align="center" mt={10}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        w={{ base: "90%", md: "80%" }}
        mb={4}
      >
        <Text fontSize="3xl" fontWeight="bold">
          Builds
        </Text>
      </Flex>
      <Box w={{ base: "90%", md: "80%" }} borderWidth="1px" borderRadius="md">
        {projectUpdates.length > 0 ? (
          projectUpdates.map((update) => (
            <>
              <Flex
                width="100%"
                height="auto"
                alignItems="center"
                p={4}
                justifyContent="space-between"
              >
                <VStack alignItems="flex-start" spacing={1}>
                  <Text fontWeight="bold">Build No. {update.id}</Text>
                  <Text>Status: {update.status || "Pending"}</Text>
                </VStack>
                <HStack>
                  {update.circleciJobId && (
                    <Button
                      colorScheme="blue"
                      onClick={() => fetchLogs(update.circleciJobId)}
                      isLoading={loadingLogs[update.circleciJobId]}
                      spinner={<Spinner />}
                    >
                      {selectedPipelineId === update.circleciJobId
                        ? "Hide Logs"
                        : "View Logs"}
                    </Button>
                  )}
                  {update.bundleUrl && (
                    <Button
                      as="a"
                      href={update.bundleUrl}
                      download
                      colorScheme="teal"
                    >
                      Download Assets
                    </Button>
                  )}
                  <Button
                    colorScheme="orange"
                    onClick={() => handleTriggerNewBuild(update)}
                  >
                    Trigger New Build
                  </Button>
                </HStack>
                {selectedPipelineId === update.circleciJobId &&
                  logs[update.circleciJobId] &&
                  logs[update.circleciJobId].length > 0 && (
                    <VStack spacing={4} mt={4} w="100%">
                      <Text fontWeight="bold">Logs:</Text>
                      {logs[update.circleciJobId].map((log, index) => (
                        <Flex
                          key={index}
                          // bg="gray.100"
                          borderRadius="lg"
                          shadow="base"
                          p={4}
                          w="100%"
                          flexDirection="column"
                        >
                          <Text>Job: {log.job.name}</Text>
                          <Text>Status: {log.job.status}</Text>
                          <Text>Started at: {log.job.started_at}</Text>
                          <Text>Stopped at: {log.job.stopped_at}</Text>
                          <Text>Steps:</Text>
                          {log.steps.map((step: Step, stepIndex: number) => (
                            <VStack
                              key={stepIndex}
                              alignItems="flex-start"
                              // bg="white"
                              borderRadius="md"
                              shadow="sm"
                              p={2}
                              mt={2}
                            >
                              <Text fontWeight="bold">Step: {step.name}</Text>
                              {step.actions.map(
                                (action: Action, actionIndex: number) => (
                                  <Box key={actionIndex} ml={4} mt={1} w="100%">
                                    <Text>Action: {action.name}</Text>
                                    <Text>Status: {action.status}</Text>
                                    <Text>Start time: {action.start_time}</Text>
                                    <Text>End time: {action.end_time}</Text>
                                    <DropdownOutput output={action.output} />
                                  </Box>
                                ),
                              )}
                            </VStack>
                          ))}
                        </Flex>
                      ))}
                    </VStack>
                  )}
              </Flex>
              <Divider />
            </>
          ))
        ) : (
          <Text>
            No Builds found. Builds are auto generated on new deployments.
          </Text>
        )}
      </Box>
    </Flex>
  );
}

function DropdownOutput({ output }: { output: string }) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box mt={2}>
      <Button size="sm" onClick={onToggle}>
        {isOpen ? "Hide Output" : "View Output"}
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Box
          p={4}
          mt={2}
          // bg="gray.50"
          borderRadius="md"
          shadow="sm"
          maxH="300px"
          overflowY="auto"
        >
          <pre>{output}</pre>
        </Box>
      </Collapse>
    </Box>
  );
}
