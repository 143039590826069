import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  useToast,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useUser, fetchUser } from "./UserContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useUser();

  const toast = useToast();
  const navigate = useNavigate();

  if (user) {
    return <Navigate to="/" />;
  }

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/login`,
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        },
      );

      if (!response.ok) {
        throw new Error("Invalid login credentials");
      }

      setUser(await fetchUser());

      toast({
        title: "Login Successful",
        description: "You have successfully logged in.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate("/projects");
    } catch (error) {
      console.error("Login error:", error);
      toast({
        title: "Login Failed",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      width="100%"
      maxW="400px"
      mx="auto"
      mt="10"
      p="5"
      borderWidth="1px"
      borderRadius="lg"
    >
      <Text fontSize="2xl" textAlign="center" mb="6">
        Log In
      </Text>
      <form onSubmit={handleLogin}>
        <VStack spacing="5">
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <Button
            width="full"
            type="submit"
            isLoading={loading}
            colorScheme="teal"
          >
            Log In
          </Button>
          <Text>
            Don't have an account?{" "}
            <ChakraLink as={Link} to="/signup" color="teal.500">
              Sign up
            </ChakraLink>
          </Text>
        </VStack>
      </form>
    </Box>
  );
};

export default Login;
