import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  useToast,
  VStack,
  Text,
  Link,
  Flex,
  Spacer,
  Box,
} from "@chakra-ui/react";
import { ProjectForm } from "./components/ProjectForm";
import GithubAuthButton from "./components/GithubAuthButton";
import { createProject } from "./api";
import LoadingInterstitial from "./components/LoadingInterstitial";

const GitHubProjects = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [repositories, setRepositories] = useState<any[]>([]);
  const [selectedRepo, setSelectedRepo] = useState({} as any);
  const [isLoading, setIsLoading] = useState(true);
  const [projectCreating, setProjectCreating] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchRepositories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/github/repositories`,
          { credentials: "include" },
        );
        if (!response.ok) {
          throw new Error("Failed to fetch repositories");
        }
        const data = await response.json();
        setRepositories(data.repositories.repositories);
        setIsAuthenticated(true);
        setIsLoading(false);
      } catch (error) {
        console.error("Fetch error:", error);
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };

    fetchRepositories();
  }, [toast]);

  const handleSubmit = async (formData: ProjectObject) => {
    const { name, email, buildCommands } = formData;
    if (!name || !email || !buildCommands || !selectedRepo) {
      toast({
        title: "Missing information",
        description: "Please fill all required fields before submitting.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setProjectCreating(true);
      const response = await createProject({
        name: name,
        email: email,
        buildCommands: buildCommands,
        projectType: "github",
        projectInfo: {
          repositoryId: selectedRepo.id,
          repositoryFullName: selectedRepo.full_name,
        },
      });

      setProjectCreating(false);

      if (!response.ok) {
        throw new Error("Failed to create project.");
      }

      toast({
        title: "Project Created",
        description: "Project successfully created.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/projects");
    } catch (error) {
      setProjectCreating(false);
      const message =
        error instanceof Error ? error.message : "Failed to create project";

      toast({
        title: "Failed to create project",
        description: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!isAuthenticated && !isLoading) {
    return (
      <VStack spacing={4} align="center" mt={10}>
        <Text mb="4">
          You need to connect to GitHub to connect a Github Repsitory.
        </Text>
        <GithubAuthButton
          Component={Button}
          props={{ colorScheme: "teal", size: "lg", minWidth: "160px" }}
          displayText="Connect to GitHub"
        />
      </VStack>
    );
  }

  return (
    <Box>
      <ProjectForm
        buildEnabled
        onSubmit={handleSubmit}
        additionalInput={
          <FormControl isRequired>
            <Flex>
              <FormLabel display={"inline"}>GitHub Repository</FormLabel>
              <Spacer />
              <GithubAuthButton
                Component={Link}
                props={{
                  textDecor: "none",
                  fontSize: "md",
                  fontWeight: "500",
                }}
                displayText="+ Add More Repos"
              />
            </Flex>
            <Select
              name="repositoryId"
              placeholder="Select repository"
              onChange={(e) => {
                const selectedRepo = repositories.find(
                  (repo) => repo.id === parseInt(e.target.value, 10),
                );
                setSelectedRepo(selectedRepo);
              }}
              value={selectedRepo.id}
            >
              {repositories.map((repo, index) => (
                <option key={index} value={repo.id}>
                  {repo.name}
                </option>
              ))}
            </Select>
          </FormControl>
        }
      />
      {projectCreating && (
        <LoadingInterstitial text="Creating Project on chain. This can take a minute or two" />
      )}
    </Box>
  );
};

export default GitHubProjects;
