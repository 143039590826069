import React, { useState } from "react";
import { Button, Box, Flex, Center, Text, Link } from "@chakra-ui/react";
import GitHubProjects from "./GithubProjects";
import AddProjectByUrl from "./AddProjectByUrl";
import ManualProjectUpload from "./ManualProjectUpload";

function CreateProject() {
  const [projectType, setProjectType] = useState("github");

  const renderProjectComponent = () => {
    switch (projectType) {
      case "github":
        return <GitHubProjects />;
      case "url":
        return <AddProjectByUrl />;
      case "manual":
        return <ManualProjectUpload />;
      default:
        return;
    }
  };

  const renderProjectDescription = () => {
    const docsText =
      "For information on how to fill out the form, please visit ";
    let descriptionText = "";
    switch (projectType) {
      case "github":
        descriptionText = "Add a project from your GitHub repositories.";
        break;
      case "url":
        descriptionText =
          "Add a project by providing a Github URL. This Project type is useful when you are unable to add a project from your GitHub repositories or when you want to add a project from a different repository. You can find the URL by clicking on the 'Code' button on the GitHub repository and copying the HTTPS URL.";
        break;
      case "manual":
        descriptionText = "Manually upload a project.";
        break;
      default:
        break;
    }

    return (
      <Text>
        {descriptionText} {docsText}{" "}
        <Link color="blue" href="https://docs.armadanetwork.com">
          our documentation.
        </Link>
      </Text>
    );
  };

  return (
    <Box p={5} borderWidth="0px" borderRadius="lg">
      <Flex justify="space-around" mb={4}>
        <Center>
          <Button
            colorScheme="teal"
            borderRadius={0}
            variant={projectType === "github" ? "solid" : "outline"}
            onClick={() => setProjectType("github")}
          >
            Add GitHub Repository
          </Button>
          <Button
            colorScheme="teal"
            borderRadius={0}
            variant={projectType === "url" ? "solid" : "outline"}
            onClick={() => setProjectType("url")}
          >
            Add Project by URL
          </Button>
          <Button
            colorScheme="teal"
            borderRadius={0}
            variant={projectType === "manual" ? "solid" : "outline"}
            onClick={() => setProjectType("manual")}
          >
            Manual Project Upload
          </Button>
        </Center>
      </Flex>
      <Center>
        <Box width={{ base: "60%" }} border="1px 1px solid black;">
          <Flex justify="center" mb={4} backgroundColor="green.50">
            {renderProjectDescription()}
          </Flex>
        </Box>
      </Center>
      <Center>
        <Box width={{ base: "90%", md: "80%" }} minWidth="300px">
          {renderProjectComponent()}
        </Box>
      </Center>
    </Box>
  );
}

export default CreateProject;
